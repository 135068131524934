import React, { useEffect } from 'react';
import DynamicImage from '@utils/functional/DynamicImage/DynamicImage';
import { StaticImage } from 'gatsby-plugin-image';
import { ToggleScroll } from '@utils/functional/ToggleScroll/ToggleScroll';

const LightBox = ({
  items,
  isOpen,
  setIsOpen,
  imageIndex,
  setImageIndex,
  tag,
}: any) => {
  const itemsCount = items.length;
  const parentTag = `${tag} | lightBox`;

  /*
    Lighbox controls
  */
  const toggleLightbox = (index: number) => {
    setIsOpen(!isOpen);
    setImageIndex(index);
  };

  useEffect(() => {
    ToggleScroll(isOpen);
  });

  const nextImage = () =>
    imageIndex < itemsCount - 1
      ? setImageIndex(imageIndex + 1)
      : setImageIndex(0);

  const prevImage = () =>
    imageIndex === 0
      ? setImageIndex(itemsCount - 1)
      : setImageIndex(imageIndex - 1);
  /*
    Lighbox controls
  */

  return (
    <>
      {isOpen ? (
        <div
          id="lightbox-view"
          className="fixed top-0 bottom-0 right-0 left-0 z-50 h-full w-full bg-white"
        >
          <div className="relative mx-auto h-full max-w-screen-xl">
            <button
              onClick={() => toggleLightbox(imageIndex)}
              className="md:left-5 absolute top-12 left-12 flex cursor-pointer items-center md:top-20"
              data-aa-tracker={`${parentTag} - 'Close'`}
            >
              <StaticImage
                src="../../../static/images/lightbox/close.svg"
                alt="Lightbox close"
                width={20}
                height={20}
                quality={90}
              />
              <span className="hidden md:inline">Close</span>
            </button>
            <div className="absolute left-0 top-1/2 h-32 w-full -translate-y-1/2 transform md:h-64">
              <button
                className="absolute left-12 flex h-32 w-32 rotate-180 cursor-pointer items-center md:left-0 md:h-64 md:w-64"
                onClick={prevImage}
                data-aa-tracker={`${parentTag} - 'Left Arrow'`}
              >
                <StaticImage
                  src="../../../static/images/lightbox/lightbox-arrow.svg"
                  alt="Lightbox arrow"
                  width={52}
                  height={52}
                  quality={90}
                />
              </button>
              <button
                className="absolute right-12 flex h-32 w-32 cursor-pointer items-center md:right-0 md:h-64 md:w-64"
                onClick={nextImage}
                data-aa-tracker={`${parentTag} - 'Right Arrow'`}
              >
                <StaticImage
                  src="../../../static/images/lightbox/lightbox-arrow.svg"
                  alt="Lightbox arrow"
                  width={52}
                  height={52}
                  quality={90}
                />
              </button>
            </div>
            {items.map((item: any, index: number) => (
              <LightBoxItem
                key={item.id}
                item={item}
                index={index}
                imageIndex={imageIndex}
              />
            ))}

            <div className="absolute top-12 left-1/2 -translate-x-1/2 md:top-auto md:bottom-20">
              {imageIndex + 1} / {itemsCount}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const LightBoxItem = ({ item, index, imageIndex }: any) => {
  return (
    <div
      key={item.id}
      className="absolute top-1/2 left-1/2 min-w-[235px] max-w-4xl -translate-x-1/2 -translate-y-1/2 transform transition-opacity ease-in"
      style={{ opacity: imageIndex === index ? '1' : '0' }}
    >
      <DynamicImage
        image={item.gatsbyImageData}
        src={item.file.url}
        width={item.file.details.image.width}
        height={item.file.details.image.height}
        alt={item.title}
        className=""
      />
    </div>
  );
};

export default LightBox;
