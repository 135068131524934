import React from 'react';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';

import Cards from '@organisms/Cards/Cards';
import Divider from '@atoms/Divider';
import Paragraph from '@molecules/Paragraph';
import AlertBanner from '@molecules/AlertBanner';
import ExpertBanner from '@molecules/ExpertBanner';
import Columns from '@molecules/Columns';
import Table from '@molecules/Table';
import Gallery from '@molecules/Gallery';
import Accordion from '@molecules/Accordion';
import ConstructedImages from '@molecules/ConstructedImages/ConstructedImages';
import List from '@molecules/List';
import OverallRating from '@molecules/OverallRating';
import BonusBreakDown from '@molecules/BonusBreakDown';
import WistiaVideo from '@molecules/WistiaVideo/wistiaVideo';
import Link from '@atoms/Link';
import HitsAndMisses from '@molecules/HitsAndMisses';
import CasinoOffers from '@molecules/CasinoOffers/CasinoOffers';

const CasinoReviewProxy = ({ partnerInfo, component }: any) => {
  switch (component.sys?.contentType.sys.id) {
    case 'accordion':
      return <Accordion data={component} />;
    case 'alertBanner':
      return <AlertBanner data={component} />;
    case 'bonusBreakdown':
      return <BonusBreakDown data={partnerInfo} />;
    case 'cards':
      return <Cards data={component} />;
    case 'columns':
      return <Columns data={component} />;
    case 'constructedImages':
      return <ConstructedImages data={component} />;
    case 'contentLink':
      return <Link data={component} />;
    case 'casinoOffers':
      return <CasinoOffers data={component} />;
    case 'divider':
      return <Divider data={component.divider} />;
    case 'expertBanner':
      return <ExpertBanner data={component} />;
    case 'gallery':
      return <Gallery data={component} />;
    case 'heading':
      return (
        <RichText
          data={component.body}
          type={RichTextTypes.FULL}
          tag={`internalName | ${component.sys?.contentType.sys.id}`}
        />
      );
    case 'list':
      return <List data={component} />;
    case 'table':
      return <Table data={component} />;
    case 'paragraph':
      return <Paragraph data={component} />;
    case 'overallRating':
      return <OverallRating data={component} />;
    case 'hitsAndMisses':
      return <HitsAndMisses data={component} />;
    case 'wistiaVideo':
      return <WistiaVideo data={component} />;
    default:
      return null;
  }
};

export default CasinoReviewProxy;
