import React, { useState } from 'react';
import DynamicImage from '@utils/functional/DynamicImage/DynamicImage';
import Lightbox from '@molecules/Lightbox';
import ConditionalWrapper from '@utils/functional/ConditionalWrapper/ConditionalWrapper';

const Gallery = ({ data: { internalName, isLightbox, items, sys } }: any) => {
  const imageClass = isLightbox ? 'cursor-pointer' : '';
  const [isLightboxOpen, setLightboxState] = useState(false);
  const [lightboxImageID, setLightboxImageID] = useState(0);
  const parentTag = `${internalName} | ${sys?.contentType.sys.id}`;

  return (
    <div className="grid grid-cols-2 gap-16 md:grid-cols-3 md:gap-28">
      {items.map((item: any, index: number) => (
        <ConditionalWrapper
          key={item.id}
          condition={isLightbox}
          wrapper={(children: any) => (
            <button
              className="focus:outline focus:outline-4 focus:outline-red-500"
              onClick={() => {
                setLightboxState(true);
                setLightboxImageID(index);
              }}
              data-aa-tracker={`${parentTag} - '${item.title}'`}
            >
              {children}
            </button>
          )}
        >
          <DynamicImage
            key={item.id}
            image={item.gatsbyImageData}
            src={item.file.url}
            width={item.file.details.image.width}
            height={item.file.details.image.height}
            alt={item.title}
            className={`rounded-sm ${imageClass}`}
          />
        </ConditionalWrapper>
      ))}
      {isLightbox && (
        <Lightbox
          items={items}
          isOpen={isLightboxOpen}
          setIsOpen={setLightboxState}
          imageIndex={lightboxImageID}
          setImageIndex={setLightboxImageID}
          tag={internalName}
        />
      )}
    </div>
  );
};

export default Gallery;
