import * as React from 'react';
import Body from '@atoms/Body';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';
import ConstructedImage from '@atoms/ConstructedImage';
import { ConstructedImageTypes } from '@utils/functional/GetImageType';
import Heading, { HeadingTags, HeadingTypes } from '@atoms/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import Link from '@atoms/Link';

const ExpertBanner = ({
  data: { internalName, linkId, title, body, expert, sys },
}: any) => {
  const parentTag = `${internalName} | ${sys.contentType.sys.id}`;

  return (
    <div
      id={linkId ? linkId : undefined}
      className="flex flex-wrap justify-between rounded-sm bg-denim-blue-100/10 py-32 px-24 lg:p-[52px]"
    >
      <div className="md:w-1/4">
        <ConstructedImage
          data={{ image: expert.image }}
          type={ConstructedImageTypes.AVATAR}
        />
        <p className="mt-20 text-16 font-medium text-grey-600 md:mb-16">
          {expert.name}
        </p>
        <div className="mb-20 flex items-stretch md:mb-0 md:w-3/4">
          {expert.twitter && (
            <Link className="flex" href={expert.twitter} tag={parentTag}>
              <ExpertSocialIcon type="Twitter" />
            </Link>
          )}
          {expert.facebook && (
            <Link className="flex" href={expert.facebook} tag={parentTag}>
              <ExpertSocialIcon type="Facebook" />
            </Link>
          )}
          {expert.youtube && (
            <Link className="flex" href={expert.youtube} tag={parentTag}>
              <ExpertSocialIcon type="YouTube" />
            </Link>
          )}
          {expert.linkedIn && (
            <Link className="flex" href={expert.linkedIn} tag={parentTag}>
              <ExpertSocialIcon type="LinkedIn" />
            </Link>
          )}
        </div>
      </div>
      <div className="md:w-3/4">
        <Heading
          className="mb-12"
          tag={HeadingTags.H3}
          type={HeadingTypes['L']}
        >
          {title}
        </Heading>
        <Body>
          <RichText data={body} type={RichTextTypes.SIMPLE} tag={parentTag} />
        </Body>
      </div>
    </div>
  );
};

const ExpertSocialIcon = ({ type }: any) => {
  const quality = 90;
  const loading = 'lazy';
  const placeholder = 'dominantColor';

  switch (type) {
    case 'Twitter':
      return (
        <StaticImage
          className="mr-8 duration-300 ease-in-out hover:brightness-75"
          loading={loading}
          placeholder={placeholder}
          src="../../../static/images/expertBanner/twitter.svg"
          width={23}
          height={20}
          alt="Twitter social icon"
          quality={quality}
        />
      );
    case 'Facebook':
      return (
        <StaticImage
          className="mr-8 duration-300 ease-in-out hover:brightness-75"
          loading={loading}
          placeholder={placeholder}
          src="../../../static/images/expertBanner/facebook.svg"
          width={14}
          height={25}
          alt="Facebook social icon"
          quality={quality}
        />
      );
    case 'YouTube':
      return (
        <StaticImage
          className="mr-8 duration-300 ease-in-out hover:brightness-75"
          loading={loading}
          placeholder={placeholder}
          src="../../../static/images/expertBanner/youtube.svg"
          width={25}
          height={18}
          alt="YouTube social icon"
          quality={quality}
        />
      );
    case 'LinkedIn':
      return (
        <StaticImage
          className="mr-8 duration-300 ease-in-out hover:brightness-75"
          loading={loading}
          placeholder={placeholder}
          src="../../../static/images/expertBanner/linkedin.svg"
          width={23}
          height={20}
          alt="Linkedin social icon"
          quality={quality}
        />
      );
    default:
      return <></>;
  }
};

export default ExpertBanner;
