export const certificateTypes = {
  'UK Gambling Commission (UKGC)': {
    internalName: 'casino offer uk-gambling-commission',
    backgroundColor: '#525051',
    image: {
      src: 'https://www.cf-ogca-static.net/flat-images/certificates/gc.png',
      alt: 'UK Gambling Commission (UKGC)',
    },
  },
  'Malta Gaming Authority (MGA)': {
    internalName: 'casino offer malta-gaming-authority',
    backgroundColor: '#2F3642',
    image: {
      src: 'https://www.cf-ogca-static.net/flat-images/certificates/mga.png',
      alt: 'Malta Gaming Authority (MGA)',
    },
  },
  'Gibraltar Betting & Gaming': {
    internalName: 'casino offer gibraltar-betting-gaming',
    backgroundColor: '#0B2012',
    image: {
      src: 'https://www.cf-ogca-static.net/flat-images/certificates/gbga.png',
      alt: 'Gibraltar Betting & Gaming',
    },
  },
  'iGaming Ontario': {
    internalName: 'casino offer igaming-ontario',
    backgroundColor: '#d7dbe2',
    image: {
      src: 'https://www.cf-ogca-static.net/flat-images/certificates/igaming-ontario.png',
      alt: 'iGaming Ontario',
    },
  },
  'eCogra': {
    internalName: 'casino offer ecogra-ontario',
    backgroundColor: '#2F3642',
    image: {
      src: 'https://www.cf-ogca-static.net/flat-images/certificates/ecogra.png',
      alt: 'eCorga Ontario',
    },
  },
};
