import React from 'react';
import { useTab } from '@utils/contexts/TabContext/TabContext';

const Tabs = ({ data: { tabs }, tabsContent }: any) => {
  const { currentTab, setTab } = useTab();

  return (
    <>
      <div className="mb-24 flex w-full overflow-x-visible border-b-1 border-stone">
        {tabs.map((tab: any) => (
          <TabButton
            key={tab.id}
            tab={tab}
            currentTab={currentTab}
            setTab={setTab}
          />
        ))}
      </div>
      {tabs.map((tab: any) => (
        <TabContent key={tab.id} isVisible={tab.id === currentTab}>
          {tabsContent[tab.id]}
        </TabContent>
      ))}
    </>
  );
};

const TabContent = ({ isVisible, children }: any) => {
  const hiddenClass = isVisible ? '' : 'hidden';

  return (
    <div className={`flex flex-col container-spacing ${hiddenClass}`}>{children}</div>
  );
};

const TabButton = ({
  tab: { internalName, id, sys, tabLabel },
  currentTab,
  setTab,
}: any) => {
  const activeClass = id === currentTab ? 'text-purple-700' : '';
  const parentTag = `${internalName} | ${sys?.contentType.sys.id}`;

  return (
    <button
      className={`relative px-24 py-16 font-medium outline-none ${activeClass}`}
      onClick={() => setTab(id)}
      data-aa-tracker={`${parentTag} - '${tabLabel}'`}
    >
      {tabLabel}
      {activeClass && (
        <span className="absolute left-0 bottom-[-5px] h-[4px] w-full bg-purple-700" />
      )}
    </button>
  );
};

export default Tabs;
