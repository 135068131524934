import Divider from '@atoms/Divider';
import Heading, { HeadingTags, HeadingTypes } from '@atoms/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { usePartnerInfo } from '@utils/contexts/PartnerInfoContext/PartnerInfoContext';
import { ConstructedImageTypes } from '@utils/functional/GetImageType';
import ConstructedImage from '@atoms/ConstructedImage';
import Link, { LinkType } from '@atoms/Link';
import { certificateTypes } from './CasinoOffers.model';

type CasinoOffersProps = {
  data: {
    title: string;
  };
};

const CasinoOffers = ({ data: { title } }: CasinoOffersProps) => {
  const partner = usePartnerInfo();
  const {
    paymentMethod,
    compatibility,
    games,
    payoutTime,
    languageSupport,
    license : paymentCertificates,
    support : customerSupport,
    supportEmail: customerSupportEmail,
  } = partner.attributes;

  const languageCount = (languageSupport.length > 3) ? ` + ${languageSupport.length - 2} others` : '';
  const languages = `${languageSupport[0]}, ${languageSupport[1]}${languageCount}`;

  return (
    <div className="flex flex-wrap gap-24 lg:flex-row">
      <div className="flex w-full flex-col">
        <Heading tag={HeadingTags.H2} type={HeadingTypes['L']}>
          {title}
        </Heading>
      </div>
      <div className="flex w-full gap-16 lg:gap-48">
        {payoutTime && (
          <div className="flex flex-col">
            <p className="text-grey-500">Payout speed</p>
            <p>{payoutTime}</p>
          </div>
        )}

        {games.length > 0 && (
          <div className="flex flex-col">
            <p className="text-grey-500">No. of games</p>
            <p>{games.replace(/\./g, ',')}</p>
          </div>
        )}

        <div className="flex flex-col">
          <p className="text-grey-500">Available in</p>

          <div className="h-[32px] w-[38px]">
            <StaticImage
              src="../../../static/images/flags/ca.png"
              alt="Canada"
              width={38}
              height={32}
              quality={100}
            />
          </div>
        </div>
      </div>
      <Divider data="Thin" />
      <div className="flex w-full flex-col gap-8">
        <p className="text-grey-500">Payment options</p>
        <div className="flex flex-wrap gap-16 lg:gap-24">
          {paymentMethod.map((item: any) => (
            <ConstructedImage
              key={item.paymentMethodName}
              useImgTag={true}
              type={ConstructedImageTypes['RECTANGLE-SM']}
              data={{
                internalName: item.paymentMethodName,
                image: {
                  src:
                    'https://www.cf-ogca-static.net/flat-images/payments/' +
                    item.image,
                  alt: item.paymentMethodName,
                  class: 'max-h-[32px]',
                },
              }}
            />
          ))}
        </div>
      </div>
      <div className="flex w-full flex-col gap-8">
        <p className="text-grey-500">Certification</p>
        <div className="flex flex-wrap gap-16 lg:gap-24">
          {paymentCertificates.map((item: string) => (
            <ConstructedImage
              key={item}
              type={ConstructedImageTypes['RECTANGLE-MD']}
              data={certificateTypes[item as keyof typeof certificateTypes]}
              useImgTag={true}
            />
          ))}
        </div>
      </div>
      <div className="flex w-full flex-wrap gap-24 lg:flex-nowrap">
        {compatibility.length > 0 && (
          <div className="flex flex-wrap gap-16 lg:flex-col lg:flex-nowrap lg:gap-24">
            <div className="flex w-full flex-col gap-8">
              <p className="text-grey-500">Device compatibility</p>
              <p>
                {compatibility.map((item: any, index: number) => (
                  <span key={item.compatibilityName}>
                    {(index ? ', ' : '') + item.compatibilityName}
                  </span>
                ))}
              </p>
            </div>

            {languageSupport.length > 0 && (
              <div className="flex flex-col gap-8">
                <p className="text-grey-500">Languages</p>
                <p>
                  {languages}
                </p>
              </div>
            )}
          </div>
        )}

        {customerSupport.length > 0 && (
          <div className="flex w-full flex-col gap-8 lg:w-1/2">
            <div className="flex flex-col gap-8">
              <p className="text-grey-500">Customer support</p>
              <p>
                {customerSupport.map((item: any, index: number) => (
                  <span key={item.supportName}>
                    {(index ? ', ' : '') + item.supportName}
                  </span>
                ))}
              </p>
            </div>
            {customerSupportEmail && (
              <Link
                className="flex items-center gap-8"
                href={`mailto:${customerSupportEmail}`}
                type={LinkType.INLINE}
              >
                <div className="h-[15px] w-[15px]">
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 0H14C14.825 0 15.5 0.675 15.5 1.5V10.5C15.5 11.325 14.825 12 14 12H2C1.175 12 0.5 11.325 0.5 10.5L0.5075 1.5C0.5075 0.675 1.175 0 2 0ZM8 6.75L14 3V1.5L8 5.25L2 1.5V3L8 6.75Z"
                      fill="#4355F9"
                    />
                  </svg>
                </div>

                {customerSupportEmail}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CasinoOffers;
