import Heading, { HeadingTags, HeadingTypes } from '@atoms/Heading';
import ProgressBar from '@atoms/ProgressBar';
import { overallRatingData } from '@molecules/Paragraph/Paragraph';
import Rating from '@molecules/Rating';
import Tooltip from '@molecules/Tooltip/Tooltip';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';
import React, { useEffect, useState } from 'react';
import { usePartnerInfo } from '@utils/contexts/PartnerInfoContext/PartnerInfoContext';

const OverallRating = ({ data }: any) => {
  const { internalName, extraTitles, title, sys, tooltipText } = data;
  const partner = usePartnerInfo();
  const parentTag = `${internalName} | ${sys.contentType.sys.id} | ${partner.attributes.casinoName}`;
  const [isLoading, setLoading] = useState(true);
  const [headings, setHeadings]: any = useState([]);

  const getAllHeadings = () => {
    if (overallRatingData.length > 0) {
      setHeadings(overallRatingData);
      setLoading(false);
    }
  };

  const scrollToElement = (id: any) => {
    const heading = document.getElementById(id);
    if (heading)
      window.scrollTo({
        top: heading.offsetTop - 100,
        behavior: 'smooth',
      });
  };

  useEffect(() => {
    getAllHeadings();
  }, []);

  return (
    <div className="z-1 rounded-sm border border-grey-300 p-24 md:p-32">
      <div className="flex flex-wrap items-center">
        <div className="flex w-full md:w-auto">
          <Heading
            className="mr-12"
            tag={HeadingTags.H2}
            type={HeadingTypes['M']}
          >
            {title}
          </Heading>
          <Tooltip parentTag={parentTag} text={tooltipText} />
        </div>

        <div className="my-8 ml-auto flex w-full items-center md:my-0 md:w-auto">
          <Rating
            data={{
              value: partner.attributes.rating,
              isSingleStar: false,
              displayValue: true,
              displayLabel: true,
              displayTooltip: false,
              valuePosition: 'left',
              displayLabelasHeading: true,
            }}
            className="mr-8"
          />
        </div>
      </div>
      <div className="flex flex-wrap justify-between gap-x-28">
        {isLoading && <div>Loading...</div>}
        <>
          {headings.map((item: any) => {
            const ratingData = {
              value: item.rating,
              maxRating: 5,
            };

            const childTag = item.heading;
            return (
              <div
                key={item.id}
                className="my-8 flex w-full shrink basis-auto items-center md:w-[calc(50%-28px)]"
              >
                <button
                  className="mr-8 w-3/5 text-left text-purple-600 underline hover:no-underline"
                  onClick={() => scrollToElement(item.id)}
                  data-aa-tracker={`${parentTag} - '${childTag}'`}
                >
                  {item.heading}
                </button>

                <div className="w-2/5">
                  <ProgressBar height="h-8" data={ratingData} />
                </div>
                <span className="ml-16 min-w-[24px]">{item.rating}</span>
              </div>
            );
          })}
          {extraTitles &&
            extraTitles.map((title: any) => {
              return (
                <div
                  key={title.id}
                  className="my-8 flex w-full shrink basis-auto items-center md:w-[calc(50%-28px)]"
                >
                  <div className="mr-8 w-3/5 text-left text-purple-600">
                    <RichText data={title.body} type={RichTextTypes.SIMPLE} />
                  </div>
                  <div className="w-2/5 text-right">
                    <p>N/A</p>
                  </div>
                </div>
              );
            })}
        </>
      </div>
    </div>
  );
};

export default OverallRating;
