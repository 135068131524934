import React, { useState } from 'react';
import MainTemplate from '@templates/MainTemplate/MainTemplate';
import { TabContext } from '@utils/contexts/TabContext/TabContext';
import { PartnerInfoContext } from '@utils/contexts/PartnerInfoContext/PartnerInfoContext';
import Section from '@utils/grid/Section/Section';
import CasinoReviewProxy from '@utils/proxies/CasinoReviewProxy/CasinoReviewProxy';

import CasinoReviewHero from '@molecules/CasinoReviewHero/CasinoReviewHero';
import Sidebar from '@molecules/Sidebar/Sidebar';
import Tabs from '@organisms/Tabs/Tabs';
import BackToTop, { BottomMargin } from '@atoms/BackToTop';
import CasinoReviewSchema from './CasinoReviewSchema';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';

const CasinoReviewTemplate = ({ pageContext: { pageData } }: any) => {
  const {
    id,
    tabs,
    components,
    hero,
    partner,
    mustReads,
    author,
    pageInfo,
    mainTemplate,
    breadcrumbLinks,
  } = pageData;
  const isTabs = !tabs ? '' : tabs.tabs[0].id;

  const [currentTab, setTab] = useState(isTabs);

  //TODO: Below section needs refactor, it needs to be handled in more generic way, however CasinoReviewProxy needs to be imported inside this template.
  let tabsContent: any = [];
  if (tabs) {
    tabs.tabs.forEach((tab: any) => {
      const content = tab.tabContent.map((section: any) => {
        if (section.isHidden) {
          return null;
        }
        return (
          <Section key={section.id} data={section} className="flex flex-col section-spacing">
            {section.components &&
              section.components.map((component: any, index: number) => {
                return (
                  <CasinoReviewProxy
                    key={component.id + index}
                    partnerInfo={partner}
                    component={component}
                    section={section.showInOverallRating ? section : undefined}
                  />
                );
              })}
          </Section>
        );
      });
      tabsContent[tab.id] = content;
    });
  }

  return (
    <PartnerInfoContext.Provider value={partner}>
      <MainTemplate data={mainTemplate} page={pageInfo}>
        <Breadcrumbs
          breadcrumbLinks={breadcrumbLinks}
          pageTitle={pageInfo.title}
          className="breadcrumb-selector col-span-full"
        />
        <CasinoReviewHero data={hero} />
        <div className="container container-spacing clear-both mb-40 grid grid-cols-4 items-start md:mb-80 pt-32 lg:pt-48">
          <TabContext.Provider value={{ currentTab, setTab }}>
            {tabs && (
              <>
                <div className="col-span-4 lg:col-span-3">
                  <Tabs key={tabs.id} data={tabs} tabsContent={tabsContent} />
                </div>
                <div className="mlg:col-span-1 hidden lg:flex lg:h-full lg:flex-col">
                  <Sidebar
                    key={`sidebar-${id}`}
                    data={pageData}
                    className="sticky md:top-[90px]"
                  />
                </div>
              </>
            )}
          </TabContext.Provider>
          <div className="col-span-4 flex w-full flex-col container-spacing">
            {components &&
              components.map((section: any) => (
                <Section
                  key={section.id}
                  data={section}
                  className="flex flex-col section-spacing"
                >
                  {section.components &&
                    section.components.map((component: any) => (
                      <CasinoReviewProxy
                        key={component.id}
                        component={component}
                      />
                    ))}
                </Section>
              ))}
          </div>
        </div>
        <div className="bg-denim-blue-100/10">
          <div className="container flex flex-col section-spacing section-with-background">
            {mustReads &&
              mustReads.map((component: any) => (
                <CasinoReviewProxy key={component.id} component={component} />
              ))}
          </div>
        </div>
        <CasinoReviewSchema author={author} />
        <BackToTop size={BottomMargin.LG} />
      </MainTemplate>
    </PartnerInfoContext.Provider>
  );
};

export default CasinoReviewTemplate;
