import React, { useEffect, useState } from 'react';
import Rating from '@molecules/Rating/Rating';
import Button, { ButtonSizes, ButtonThemes } from '@atoms/Button';
import Body, { BodyColor, BodySize, BodyWeight } from '@atoms/Body';
import Link, { LinkColor, LinkSize, LinkType } from '@atoms/Link';

import { usePartnerInfo } from '@utils/contexts/PartnerInfoContext/PartnerInfoContext';
import { usePageInfo } from '@utils/contexts/PageInfoContext/PageInfoContext';
import { DisplayBonus } from '@utils/functional/DisplayBonus/DisplayBonus';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

export type FloatingBannerProps = {
  data: {
    isIntersecting: boolean;
    bonusText: string;
    termsAndConditionsLink: any;
    partnerLink: any;
  };
};

const FloatingBanner = ({ data }: FloatingBannerProps) => {
  const partner = usePartnerInfo();
  const [isMobile, setMobileState] = useState(false);
  const { localization, echoProxy } = usePageInfo();
  const parentTag = `${'internalName'} | FloatingBanner`;

  const { afflinkProxy, tncLinkProxy, amount1, titlePreview } =
    partner.attributes.offers[0].attributes;
  const bonusValue = amount1 ? `$${DisplayBonus(amount1)}` : titlePreview;

  const transition = data.isIntersecting ? 'translate-y-0' : 'translate-y-full';
  const partnerLink = afflinkProxy ? `${echoProxy}${afflinkProxy}` : '';
  const partnerTnCLink =
    afflinkProxy && tncLinkProxy
      ? `${echoProxy}${tncLinkProxy}`
      : afflinkProxy
      ? `${echoProxy}${afflinkProxy}`
      : '';

  if (isBrowser) {
    useEffect(() => {
      if (window.innerWidth <= 800) {
        return setMobileState(true);
      }
    }, []);

    useEffect(() => {
      const windowSize = () => {
        if (window.innerWidth <= 800) {
          setMobileState(true);
        } else {
          setMobileState(false);
        }
      };

      window.addEventListener('resize', windowSize);
      return () => window.removeEventListener('resize', windowSize);
    }, [window.innerWidth]);
  }

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 top-auto z-20 w-full bg-white pt-12 shadow-md-grey 
      transition-transform duration-500 ease-in-out lg:px-12 ${transition}`}
    >
      <div className="container text-black">
        <div className="float-left flex	w-1/2 flex-col">
          {!localization.displayBonus && (
            <>
              <Body
                className="text-15 pr-20 font-semibold leading-170 md:text-25"
                weight={BodyWeight.SEMIBOLD}
              >
                {partner.contentfulName}
              </Body>
              <Rating
                data={{
                  value: partner.attributes.rating,
                  isSingleStar: true,
                  displayValue: true,
                  displayMax: true,
                }}
              />
            </>
          )}
          {localization.displayBonus && !isMobile && (
            <>
              <Body
                className="text-15 pr-20 font-semibold leading-170 md:text-25"
                weight={BodyWeight.SEMIBOLD}
              >
                {partner.contentfulName}
              </Body>
              <Rating
                data={{
                  value: partner.attributes.rating,
                  isSingleStar: true,
                  displayValue: true,
                  displayMax: true,
                }}
              />
            </>
          )}
        </div>

        {amount1 && localization.displayBonus && (
          <div className="float-left w-1/2 items-start md:w-3/12 lg:w-2/12 xl:w-2/12">
            <Body size={BodySize.L} weight={BodyWeight.SEMIBOLD}>
              {bonusValue}
            </Body>
            <Body
              size={BodySize.M}
              weight={BodyWeight.REGULAR}
              color={BodyColor.MEDIUM}
            >
              {data.bonusText}
            </Body>
          </div>
        )}

        {partner.bonusvalue && (
          <div className="bottom-28 float-left hidden w-1/2 pt-44 md:-ml-40 md:block md:w-2/12 lg:-ml-28 lg:w-1/12 xl:-ml-72 xl:w-1/12">
            <Link
              href={partnerTnCLink}
              color={LinkColor.MEDIUM}
              size={LinkSize.XS}
              type={LinkType.INLINE}
              tag={`${parentTag} stickyBannerTerms | ${partner.attributes.casinoName}`}
            >
              {data.termsAndConditionsLink.text}
            </Link>
          </div>
        )}
        {partnerLink && Object.keys(partnerLink).length !== 0 && (
          <div className="float-right w-1/2 py-4 pb-16 pt-16 text-right md:w-2/12 md:pt-16 md:pb-28 lg:w-2/12 xl:w-2/12">
            <Button
              size={ButtonSizes.LARGE}
              theme={ButtonThemes.PRIMARY}
              className="px-0 py-0 md:-ml-40"
              tag={`${parentTag} stickyBannerButton | ${partner.attributes.casinoName}`}
            >
              <Link
                href={partnerLink}
                tag={`${parentTag} stickyBannerPartnerLink | ${partner.attributes.casinoName}`}
                color={LinkColor.LIGHT}
                size={LinkSize.MD}
                type={LinkType.NONE}
                className="block px-24 py-12"
              >
                {data.partnerLink.text}
              </Link>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatingBanner;
