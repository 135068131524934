import React, { useEffect, useRef, useState } from 'react';
import ConstructedImage from '@atoms/ConstructedImage';
import { ConstructedImageTypes } from '@utils/functional/GetImageType';

import Heading, { HeadingTags, HeadingTypes } from '@atoms/Heading';
import Rating from '@molecules/Rating/Rating';
import Button, { ButtonSizes, ButtonThemes } from '@atoms/Button';
import Body, { BodySize, BodyWeight } from '@atoms/Body';
import RichText, { RichTextTypes } from '@utils/parsers/RichText';
import Link, { LinkColor, LinkSize, LinkType } from '@atoms/Link';
import OverlayLabel, { OverlayType, OverlayColor } from '@atoms/OverlayLabel';
import FloatingBanner from '@atoms/FloatingBanner/FloatingBanner';

import { usePartnerInfo } from '@utils/contexts/PartnerInfoContext/PartnerInfoContext';
import { usePageInfo } from '@utils/contexts/PageInfoContext/PageInfoContext';
import Preloader, { PreloadTypes } from '@utils/functional/Preloader';
import { DisplayBonus } from '@utils/functional/DisplayBonus/DisplayBonus';

const CasinoReviewHero = ({ data }: any) => {
  const {
    internalName,
    sys,
    tooltipText,
    imageBackgroundColour,
    bonusText,
    termsAndConditionsLink,
    partnerLink,
    legalText,
  } = data;
  const parentTag = `${internalName} | ${sys.contentType.sys.id}`;
  const ref: any = useRef<HTMLDivElement>();
  const partner = usePartnerInfo();
  const { localization, echoProxy } = usePageInfo();
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  const { afflinkProxy, tncLinkProxy, amount1, titlePreview } =
    partner.attributes.offers[0].attributes;
  const backgroundColor = imageBackgroundColour
    ? imageBackgroundColour
    : '#EFF1F4';

  const partnerProxy = afflinkProxy ? `${echoProxy}${afflinkProxy}` : '';
  const partnerTnCLink =
    afflinkProxy && tncLinkProxy
      ? `${echoProxy}${tncLinkProxy}`
      : afflinkProxy
      ? `${echoProxy}${afflinkProxy}`
      : '';

  const bonusValue = amount1 ? `$${DisplayBonus(amount1)}` : titlePreview;

  const FloatingBannerData = {
    isIntersecting,
    bonusText,
    partnerLink,
    termsAndConditionsLink,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(!entry.isIntersecting);
      },
      { rootMargin: `0px 0px 0px 0px` }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  });

  return (
    <>
      {/* START preload elements */}
      {partner.image && (
        <Preloader
          elements={[
            {
              id: 'logo_' + partner.image,
              href: partner.image.file.url,
              type: PreloadTypes.IMAGE,
            },
          ]}
        />
      )}
      {/* END preload elements */}
      {/* START Hero component */}
      <div ref={ref} className="w-full bg-grey-050">
        <div className="container grid gap-8 pt-48 pb-16 md:grid-cols-[4fr_1fr_4fr] md:gap-24 md:py-24 md:pt-64 lg:pb-40 lg:md:grid-cols-[3fr_2fr_4fr]">
          <div className="flex flex-col gap-8 md:col-start-2 md:col-end-4">
            <Heading
              tag={HeadingTags.H1}
              type={HeadingTypes.CUSTOM}
              className="text-25 leading-130 md:text-39 lg:text-48"
            >
              {partner.contentfulName} review {new Date().getFullYear()}{' '}
            </Heading>
            <Rating
              data={{
                value: partner.attributes.rating,
                isSingleStar: false,
                displayValue: true,
                displayLabel: true,
                displayTooltip: true,
                toolTip: tooltipText,
                parentTag: parentTag,
              }}
              className="mr-12"
            />
          </div>
          <div className="flex flex-col gap-8 md:col-start-2 md:col-end-4">
            {legalText && (
              <Body
                size={BodySize.S}
                className="leading-30 text-14"
              >
                <RichText
                  data={legalText}
                  type={RichTextTypes.SIMPLE}
                  tag={parentTag}
                />
              </Body>
            )}
          </div>
          <div className="z-0 w-full md:col-start-1 md:row-start-1 md:row-end-3 lg:row-end-4">
            <OverlayLabel
              type={OverlayType.MD}
              color={OverlayColor.DEFAULT}
              text={partner.contentfulPartnerLabel}
              displayIcon={false}
            >
              <ConstructedImage
                data={{ image: partner.image, backgroundColor }}
                type={ConstructedImageTypes.PARTNER}
                hasImageContainer={true}
              />
            </OverlayLabel>
          </div>
          {partnerProxy && Object.keys(partnerProxy).length !== 0 && (
            <div className="flex flex-col items-center gap-8 md:col-start-2 md:col-end-4 md:gap-24 lg:flex-row lg:gap-48">
              {amount1 && localization.displayBonus && (
                <div className="w-full lg:w-auto">
                  <Body
                    size={BodySize.XL}
                    weight={BodyWeight.SEMIBOLD}
                    className="text-oxford-blue-700"
                  >
                    {bonusValue}
                  </Body>

                  {partner.contentfulBonusText}

                  <Link
                    href={partnerTnCLink}
                    color={LinkColor.NONE}
                    size={LinkSize.SM}
                    type={LinkType.INLINE}
                    className="ml-8 text-denim-blue-500 md:ml-16"
                    tag={`${parentTag} heroTerms | ${partner.attributes.casinoName}`}
                  >
                    {data.termsAndConditionsLink.text}
                  </Link>
                </div>
              )}

              <Button
                size={ButtonSizes.LARGE}
                theme={ButtonThemes.PRIMARY}
                className="w-full px-0 py-0 md:col-span-full md:row-start-4 lg:col-span-1 lg:row-start-auto lg:!w-fit"
                tag={`${parentTag} heroButton | ${partner.attributes.casinoName}`}
              >
                <Link
                  href={partnerProxy}
                  tag={`${parentTag} heroPartnerLink | ${partner.attributes.casinoName}`}
                  color={LinkColor.LIGHT}
                  size={LinkSize.MD}
                  type={LinkType.NONE}
                  className="block px-24 py-12"
                >
                  {data.partnerLink.text}
                </Link>
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* END Hero component */}

      <FloatingBanner data={FloatingBannerData} />
    </>
  );
};

export default CasinoReviewHero;
