import { createContext, useContext } from 'react';

export const PartnerInfo = {
  partner: {},
};

export const PartnerInfoContext = createContext<any>({
  partner: PartnerInfo.partner,
});

export const usePartnerInfo = () => useContext(PartnerInfoContext);
