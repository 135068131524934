import React from 'react';
import Heading, { HeadingTags, HeadingTypes } from '@atoms/Heading';
import Tooltip, { TooltipTypes } from '@molecules/Tooltip';
import CircularProgress from '@atoms/CircularProgress/CircularProgress';

const BonusBreakDown = ({
  data: {
    contentfulNoOfDeposits,
    contentfulMinDepositBonus,
    contentfulWageringRequirements,
    attributes,
    internalName,
    sys,
    contentfulBonusBreakdownText,
  },
}: any) => {
  const bonusValue: number = parseInt(contentfulWageringRequirements);
  let circularColour = '#059669';
  const parentTag = `${internalName} | ${sys.contentType.sys.id} ToolTip`;

  if (bonusValue > 35 && bonusValue < 55) {
    circularColour = '#F59E0B';
  } else if (bonusValue > 55) {
    circularColour = '#dc2626';
  }

  return (
    <div className="flex flex-wrap items-center justify-center gap-48 rounded-md border border-grey-300 p-32 md:flex-nowrap">
      <div className="w-full text-center md:w-1/4 md:text-left">
        <Heading tag={HeadingTags.H4} type={HeadingTypes['L']}>
          ${attributes.offers[0].attributes.titlePreview}
        </Heading>
        <span className="text-grey-500">Welcome bonus</span>
      </div>
      <div className="relative w-[132px]">
        <CircularProgress
          size={132}
          strokeWidth={12}
          percentage={bonusValue}
          color={circularColour}
        />
        <div className="flex justify-center">
          <p className="mr-4 mt-4 text-grey-500">Wagering</p>
          <Tooltip parentTag={parentTag} type={TooltipTypes.WAGERING} />
        </div>
      </div>
      <div className="w-full text-grey-500 md:w-2/4">
        {contentfulNoOfDeposits && (
          <BreakDownInfo title="No. of deposits" description={contentfulNoOfDeposits} />
        )}
        {contentfulMinDepositBonus && (
          <BreakDownInfo
            title="Minimum deposit"
            description={contentfulMinDepositBonus}
          />
        )}
        {contentfulWageringRequirements && (
          <BreakDownInfo
            title="Wagering requirement"
            description={contentfulWageringRequirements + 'x'}
          />
        )}
        {contentfulBonusBreakdownText && (
          <BreakDownInfo
            title="Bonus Code"
            description={contentfulBonusBreakdownText + ''}
          />
        )}
      </div>
    </div>
  );
};

const BreakDownInfo = ({ title, description }: any) => {
  return (
    <>
      <p>{title}</p>
      <p className="mb-8 block font-semibold text-grey-600">{description}</p>
    </>
  );
};

export default BonusBreakDown;
