import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

const WistiaVideo = ({ data: { videoId } }: any) => {
  return (
    <>
      <Script
        src={`//fast.wistia.com/embed/medias/${videoId}.jsonp`}
        strategy={ScriptStrategy.idle}
      />
      <Script
        src="//fast.wistia.com/assets/external/E-v1.js"
        strategy={ScriptStrategy.idle}
      />
      <div className="wistia_responsive_padding relative pt-[56.25%]">
        <div className="wistia_responsive_wrapper absolute left-0 top-0 h-full w-full">
          <div
            className={`wistia_embed wistia_async_${videoId} seo=true videoFoam=true h-full w-full`}
          >
            <div className="wistia_swatch absolute left-0 top-0 h-full w-full overflow-hidden">
              <img
                src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
                className="h-full w-full"
                alt="Video placeholder"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WistiaVideo;
