import React, { useEffect, useState, useRef } from 'react';
import useOnScreen from '@hooks/useOnScreen';

const CircularProgress = ({ size, strokeWidth, percentage, color }: any) => {
  const [progress, setProgress] = useState(0);
  const ref: any = useRef<HTMLDivElement>();
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '-100px');

  useEffect(() => {
    if (onScreen) {
      setProgress(percentage);
    }
  }, [onScreen]);

  const viewBox = `0 0 ${size} ${size}`;
  const radius: number = (size - strokeWidth) / 2;
  const circumference: number = radius * Math.PI * 2;
  const dash: number = (progress * circumference) / 100;
  const strokeDasharray: any = [dash, circumference - dash] || '';

  return (
    <svg ref={ref} width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#d7dbe2"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
        style={{ transition: 'all 1s' }}
      />
      <text
        fill={color}
        x="50%"
        y="50%"
        dy="20px"
        textAnchor="middle"
        className="-translate-y-12 text-20 font-semibold leading-150 md:text-25"
      >
        {`${percentage}x`}
      </text>
    </svg>
  );
};

export default CircularProgress;
