import React from 'react';
import { usePageInfo } from '@utils/contexts/PageInfoContext/PageInfoContext';
import { usePartnerInfo } from '@utils/contexts/PartnerInfoContext/PartnerInfoContext';

const CasinoReviewSchema = ({ author }: any) => {
  const { description } = usePageInfo();
  const partner = usePartnerInfo();

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
            "@context":"http://schema.org/",
            "@type":"Review",
            "description":"${description}",
            "image":"https://www.onlinegambling.ca",
            "itemReviewed": {
                "@type":"Organization",
                "name":"${partner.attributes.casinoName}"
            },
            "author":{
                "@type":"Person",
                "name":"${author.name}",
                "url":"${author.twitter}",
                "sameAs":["${author.linkedIn}"]
            },
            "publisher":{
                "@type":"Organization",
                "name":"Onlinegambling.ca",
                "url":"https://www.onlinegambling.ca",
                "logo":{
                    "@type":"ImageObject",
                    "url":"https://www.onlinegambling.ca/images/header/logo/new-logo-desktop.svg"
                }
            },
            "reviewRating":{
                "@type":"Rating",
                "worstRating":"0",
                "bestRating":"10",
                "ratingValue":"${partner.rating * 2}"
            }
        }`,
      }}
    />
  );
};

export default CasinoReviewSchema;
