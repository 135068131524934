import React from 'react';
import { Helmet } from 'react-helmet';
import { PreloadTypes } from '@utils/functional/Preloader/Preloader.model';

export type PreloaderProps = {
  elements: Array<{ href: string; type: PreloadTypes; id: string }>;
};

const Preloader = (props: PreloaderProps) => {
  return (
    <Helmet>
      {props.elements &&
        props.elements.map((element) => {
          switch (element.type) {
            case PreloadTypes.FONT:
              return (
                <link
                  key={element.id}
                  rel="preload"
                  href={element.href}
                  as="font"
                  type="font/woff2"
                  crossOrigin="anonymous"
                />
              );
            case PreloadTypes.IMAGE:
              return (
                <link
                  key={element.id}
                  rel="preload"
                  href={element.href}
                  as="image"
                  crossOrigin="anonymous"
                />
              );
            case PreloadTypes.SCRIPT:
              return (
                <link
                  key={element.id}
                  rel="preload"
                  href={element.href}
                  as="script"
                  crossOrigin="anonymous"
                />
              );
            case PreloadTypes.STYLE:
              return (
                <link
                  key={element.id}
                  rel="preload"
                  href={element.href}
                  as="style"
                  crossOrigin="anonymous"
                />
              );
            case PreloadTypes.VIDEO:
              return (
                <link
                  key={element.id}
                  rel="preload"
                  href={element.href}
                  as="video"
                  type={'video/' + element.href.split('.').pop()}
                  crossOrigin="anonymous"
                />
              );
            default:
              return (
                <link
                  key={element.id}
                  rel="preload"
                  href={element.href}
                  crossOrigin="anonymous"
                />
              );
          }
        })}
    </Helmet>
  );
};

export default Preloader;
